import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import SchemaOrg from "./SchemaOrg"
import { useStaticQuery, graphql } from "gatsby"

import favicon from "../images/favicon-144x144.png"

const SEO = ({ title, desc, img, url }) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultUrl,
      defaultImage,
      defaultTitle,
      defaultDescription,
      defaultTitleTemplate,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    // image: `${img || defaultImage}`,
    image: img || defaultImage,
    url: url || defaultUrl,
    titleTemplate: defaultTitleTemplate,
  }

  return (
    <React.Fragment>
      <Helmet>
        {/* General tags */}

        <title>
          {seo.title} {seo.titleTemplate}
        </title>
        <meta name="description" content={seo.description} />

        <meta name="image" content={seo.image} />
        <link rel="canonical" href={seo.url} />
        <link rel="shortcut icon" type="image/x-icon" href={favicon} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={seo.url} />
        <meta
          property="og:title"
          content="Azalea Bar & Kitchen, Restaurant & Bar in Asheville, NC"
        />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content={seo.image} />

        <meta name="twitter:title" content={seo.title} />
      </Helmet>
      <SchemaOrg />
    </React.Fragment>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  img: PropTypes.string,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  img: null,
  node: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultUrl: siteUrl
        defaultImage: image
        defaultTitle: title
        defaultDescription: description
        defaultTitleTemplate: titleTemplate
      }
    }
  }
`
